@import 'variable';
@import 'reset';
@import 'fonts.css';
html{
	font-size: 10px;
	// &.ios{
	// 	position: fixed;
	// 	left:0;
	// 	top:0;
	// 	bottom:0;
	// 	right:0;
	// }
}

*{
	box-sizing: border-box;
}
body{
	// position: fixed;
	background-color: #878d8f;
	color:$color;
	font-size: 1.6rem;
	// letter-spacing: 0.1em;
	color:#fff;
	font-family:HelveticaNeueLTStd-ThCn, helvetica, sans-serif;
	&.loading{
		// width:100vw;
		// height:100vh;
		cursor: progress;
		&:after{
			content:"";
			position: fixed;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			width:64px;
			height:64px;
			background: url('../images/loading.gif') no-repeat 0 0;
			z-index: 9999;
		}
		&:before{
			content:"";
			position: fixed;
			top:0;
			left:0;
			right:0;
			bottom:0;
			// width:100vh;
			// height:130%;
			background-color: rgba(0,0,0,0.1);
			z-index: 999;
		}

		.loading{
			&:before,&:after{
				display: none;
			}
		}

	}

	.__react_component_tooltip.type-light{
		padding: 8px 21px 3px;
	}
}

button{
	outline: none;
}

.form-login{
	p{
		margin: 8px 0;
		color:#1c1c1c;
	}
	label{
		display: block;
		margin-bottom: 6px;
	}
	input{
		width: 320px;
		line-height: 1;
		background-color: rgba(255,255,255,0.8);
		// letter-spacing: 0.1em;
	}
	#error-login{
		margin-top: 15px;
	}
	button{
		outline: none;
		width: 132px;
		// padding: 11px 0;
		text-align: center;
		// line-height: 1;
		font-weight: 700;
		letter-spacing: 0.12em;
	}
	h1{
		margin-bottom: 47px!important;
	}

	a{
		color:#ffff;
	}
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

// .clearfix {
//     overflow: auto;
// }

#poi-editor-wrapper{
	@extend .clearfix;
	margin:6% auto;
	width:1000px;
	max-width: 100%;
	// position: relative;
	#poi-wrapper{
		background-color: #ededed;
		transform-origin:0 0;
		overflow: hidden;
		position: relative;
		&.loading{
			cursor: progress;
			&:after{
				content:"";
				position: absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				width:64px;
				height:64px;
				background: url('../images/loading.gif') no-repeat 0 0;
				z-index: 9999;
			}
			&:before{
				content:"";
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background-color: rgba(0,0,0,0.1);
				z-index: 999;
			}
		}
	}
	.order-button{
		text-align: right;
		background: #fff;
		padding-top: 20px;
		button{
			display: inline-block;
			padding: 5px 15px;
			border-radius: 3px;
			background-color: #035b9b;
			font-size: 16px;
			color:#fff;
		}
	}


}

#poi-editor{
	// float: left;
	position: relative;

	.konvajs-content{
		overflow: hidden;
	}
}

textarea:disabled{
	background-color: #ddd;
}

#poi-sidebar{
	// float: right;
	// border:1px solid #;
	// padding: 10px 10px 0;
	color:#000;
	height: 58px;
	.list-poi-boxes{
		padding: 0;
		margin: 0;
	}
	ul{
		@extend .clearfix;
		list-style: none;
		li{
			float: left;
		}
	}

	button{
		&:focus{
			border:none;
			outline: none;
		}
	}

	.button{
		width:58px;
		height:58px;
		cursor: pointer;
		display: inline-block;
		border:none;
		border-right:1px solid #aeaeae;
		// transition:all 0.4s;
		&:hover{
			background-color: #cdcdcd!important;
		}

		&.disabled{
			opacity: 0.4;
			cursor: default;
			background-color: transparent !important;
		}
	}

	#poi-insert-bg,#poi-edit-bg{
		background: url('../images/add-image.png') no-repeat 50% 50%;
		// background-size: contain;
		// overflow: hidden;
		display: inline-block;
		input{
			opacity: 0;
			height:58px;
			cursor: pointer;
			display: inline-block;
		}
	}

	#poi-edit-bg{
		background-image: url('../images/edit-image.png');
	}

	#poi-delete-bg{
		background: url('../images/delete.png') no-repeat 50% 50%;
		text-indent:-9999px;
	}

	.group-drop-btn{
		width:58px;
		height:58px;
		cursor: pointer;
		display: inline-block;
		position: relative;
		.react-draggable{
			position: absolute;
			z-index: 9;
			top:0;
			left:0;
			right:0;
			bottom:0;
			text-align: center;
			padding-top: 10px;
		}
	}
	#poi-pindrop{
		text-align: center;
		position: relative;
		vertical-align: middle;
		background: #50bfdf;
		-webkit-user-drag:element;
		&:before{
			content:"";
			height:100%;
			display: inline-block;
			// margin-top: -50%;
			vertical-align: middle;
		}
		img{
			vertical-align: middle;
			display: inline-block;
		}

		&:hover{
			background: #35a8ca!important;
		}
	}

	#order{
		float: right;
		span{
			border: none;
			background: url('../images/send.png') no-repeat 50% 50%;
			text-indent: -9999px;
			width: 130px;
			border-left:1px solid #aeaeae;
			text-align: center;
		}
	}

	#poi-save{
		background: url('../images/save.png') no-repeat 50% 50%;
		text-indent: -9999px;
		display: inline-block;
		cursor: default;
		opacity: 0.4;
		&.active{
			// background-color: #cdcdcd!important;
			cursor: pointer;
			opacity: 1;
		}
	}


	#photo-slider{
		float: right;
		// margin-right:15%;
		font-size: 16px;
		// color:#116089;
		.button{
			background: url('../images/next.png') no-repeat 50% 50%;
			text-indent:-9999px;
		}

		#poi-prev{
			transform:scale(-1,-1);
			border-left:1px solid #aeaeae;
		}
		.photo-text{
			display: inline-block;
			margin-top: 35px;
			margin-right: 15px;
			font-size: 1.2rem;
			font-family: 'HelveticaNeueLTStd LtEx';
			letter-spacing: 0.1em;
			text-transform: uppercase;
			// font-weight: 700;
			letter-spacing: 0.07em;
		}

		span{
			float: left;
		}
	}
}

.container{
	padding-left: 6%;
	padding-right: 6%;
	// overflow-x: hidden;
	// max-width: 1000px;
	// margin: 0 auto;
}

.wrapper{

}

.header{
	background-color: #000000;
	color:#fff;
	height: 50px;
	position: relative;
	.logo{
		color:inherit;
		text-decoration: none;
		font-size: 30px;
		text-transform: uppercase;
		margin-top: 8px;
		display: inline-block;
		width:207px;
		float: left;
	}

	.single-header{
		text-align: center;
		width:calc(100% - 207px);
		// display: table;
		// width: 100%;
		letter-spacing: 0.1em;
		float: left;
		#project-title{
			font-size: 24px;
			text-transform: uppercase;
			margin-top: 20px;
			margin-left: -207px;
			display: inline-block;
		}
		a{
			display: none;
			position: absolute;
			right:0;
			// display: inline-block;
			height: 50px;
			padding-right: 15px;
			color: rgba(255,255,255,0.91);
			text-decoration: none;
			// font-size: 1.8rem;
			background: #454545 url('../images/arrow.png') no-repeat 10px 50%;
			padding-left: 35px;
			border-left:1px solid transparent;
			transition: all 0.3s;
			&:hover{
				background-color: transparent;
				border-left-color:#454545;
			}
			span{
				position: relative;
				top:18px;
			}
		}
	}
}


body.project{
	.single-header a{
		display: inline-block;
	}
}

.list-projects{
	list-style: none;
	padding: 0;
	max-width: 325px;
	li{
		margin-bottom: 8px;
		background-color: rgba(255,255,255,0.7);
		border-radius: 13px;
		padding: 6px 5px 4px 20px;
		a{
			text-decoration: none;
			display: inline-block;
			// min-width: 150px;
			letter-spacing: 0.1em;
			font-size: 1.8rem;
			text-transform: capitalize;
			color:#1c1c1c;
			// font-weight: 700;
		}
		.delete{
			border-radius: 5px;
			width:18px;
			height: 18px;
			padding: 0;
			margin-right: 5px;
			float: right;
			line-height: 0;
			// border:1px solid #f00;
			cursor: pointer;
			text-indent: -9999px;
			border:none;
			text-transform: uppercase;
			font-size: 18px;
			color:#f00;
			font-family: arial;
			background: url('../images/delete.png') no-repeat;
			background-size: contain;
			&:focus{
				outline: none;
			}
		}
		&:last-child{
			margin-top: 15px;
			background-color: transparent;
			padding: 0;
			margin-top: 8rem;
		}
	}
}

// .loading{
// 	position: fixed;
// 	top:45%;
// 	left:50%;
// 	transform:translate(-50%,-50%);
// }

#logout{
	border:none;
	border-radius:3px;
	padding: 5px 10px;
	float: right;
	margin-top:10px;
	background-color: #fff;
	&:focus{
		outline:0;
	}
}

.new-project{
	p{
		margin:8px 0;

	}
	label{
		display: block;
		margin-bottom: 10px;
	}
	button{
		margin-top: 5rem;
	}

	input[type=text]{
		width: 320px;
	}

	.up-files{
		width: 190px;
		position: relative;
		background-color: #fff;
		font-size: 1.8rem;
		color:#1c1c1c;
		height: 30px;
		display: inline-block;
		line-height: 35px;
		padding: 0 20px;
		border-radius: 15px;
		input{
			opacity: 0;
			position: absolute;
			left: 0;
			z-index: 1;
			height: 100%;
			width: 100%;
		}
	}
}


[draggable=true] {
  -khtml-user-drag: element;
}

input[type=text],input[type=password]{
	// height: 30px;
	border-radius: 25px;
	padding: 8px 17px 4px;
	// line-height: 35px;
	display: inline-block;
	letter-spacing: 0.1em;
	border: none;
	outline: none;
	&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder { /* Firefox 18- */
	  letter-spacing: 0.1em;
	}
}

// input[type=file]{
// 	line-height: 30px;
// 	border-radius: 15px;
// }

.project-layout{
	width: 100%;
	max-width: 765px;
	margin: 0 auto;
	padding-top: 10%;
	h1{
		border-bottom:1px solid rgba(255,255,255,0.6);
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 400;
		line-height: 1;
		letter-spacing: 0.13em;
		margin-bottom: 35px;
		color:rgba(255,255,255,0.7);

	}

	p input{
		letter-spacing: 0.1em;
	}

}


.main-btn{
	height: 30px;
	padding: 0;
	line-height: 36px;
	background-color: #000;
	border-radius: 15px;
	padding: 0 15px;
	font-size: 1.4rem;
	letter-spacing: 0.1em;
	font-family: 'Helvetica Neue LT';
	text-transform: uppercase;
	font-weight: 700;
	border: none;
	color: rgba(255,255,255,0.67)
}


html.windows{
	.main-btn{
		line-height: 30px;
		padding-top:0!important;
		padding-bottom: 0!important;
	}

	input[type="text"], input[type="password"]{
		padding: 6px 17px;
	}
	.new-project .up-files{
		line-height: 30px;
	}
}


// v2
#poi-editor-wrapper.poi-editor-wrapperv2{
	width:100%;
	max-width: 1600px;
	@media(min-width:1200px){
		#poi-wrapper{
			width: 62.5%;
			float: left;
		}

		#poi-right-sidebar{
			float: left;
			width: 37.5%;
			padding-left: 5%;
		}
	}

	@media(max-width:1199px){
		#poi-right-sidebar{
			padding: 15px 0;
			.box{
				width:48%;
				float: left;
				&:nth-child(even){
					margin-left: 4%;
				}
				// &:nth-child(1),&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){
				// 	float: left;
				// }

				// &:nth-child(6),&:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10){
				// 	float: right;
				// 	margin-left: 4%;
				// }

				font-size: 14px;

				.pin-drop{
					max-width: 25px;
				}

				.box-content{
					margin-left: 30px;
					margin-right: 30px;
					label{
						width: 50px;
						text-align: center;
						padding-left:0;
					}
					textarea{
						padding: 5px 10px 3px;
						width: calc(100% - 50px);
					}
				}

				a{
					width: 20px;
				}

			}
		}
	}
}

#poi-right-sidebar{
	.box{
		margin-bottom: 10px;
		position: relative;
		.box-content{
			// position: relative;
			border-bottom:1px solid #ddd;
			margin-left: 50px;
			margin-right: 50px;
			label{
				// margin-right: 13px;
				float: left;
				width: 80px;
				letter-spacing: 0.05em;
				// word-spacing: 0.1em;
				padding-left: 20px;
				padding-top: 15px;
			}
			textarea{
				width: calc(100% - 80px);
				float: left;
				padding: 8px 15px;
				outline: 0;
				margin-bottom: -1px;
			}
		}
		.pin-drop{
			position: relative;
			float: left;
			max-width: 40px;
			img{
				max-width: 100%;
			}
		}

		&.added{
			.pin-drop:before{
				position: absolute;
				z-index: 1;
				width:100%;
				height: 100%;
				content:"";
			}
			.pin-drop{
				img{
					filter:grayscale(80%);
				}
			}
		}

		a{
			position: absolute;
			right: 0;
			cursor: pointer;
			top:40%;
			transform:translateY(-50%);
			background: url('../images/delete.png') no-repeat 50% 50%;
			background-size: contain;
			width: 25px;
			height: 25px;
			text-align: left;
			text-indent: -9999px;
			text-align: left;
		}
	}
}

.ovhid{
	overflow: hidden;
}

#video-wrap{
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color: rgba(0,0,0,0.7);
	.inner{
		position: absolute;
		top:50%;
		transform:translateY(-50%);
		left:5%;
		right:5%;
		@media(min-width:1800px){
			left:8%;
			right:8%;
		}
	}
	a{
		position: absolute;
		right:3%;
		top:3%;
		cursor:pointer;
		width:40px;
		text-indent: -9999px;
		height:40px;
		font-weight: 400;
		background:url('../images/close-white.png') no-repeat 0 0;
		background-size: contain;
	}
}


#video-wrap video{
	width: 100%;
}